<template>
	<b-table borderless striped responsive hover large v-if="task.members_responses && tableFields"
		:thead-class="tableHeader"  :fields="tableFields"  :items="tableItems" >
		<template #head()="data">
			<p v-b-tooltip.left.hover.viewport :title="data.field.hover" class="p-2">
				<span>{{ data.label }}</span>
				<svg-info-icon v-if="data.field.hover" class="info-icon"/>
			</p>
		</template>
		<template #cell(name)="row">
			<p class="p-2">
				<b v-if="row.item.name && !row.item.group">{{ row.item.name }}</b>
				<b v-if="row.item.group">{{ row.item.group.name }}</b>
				<b v-if="row.item.user && !row.item.group">{{row.item.user.name}} {{row.item.user.surname}}</b>
			</p>
		</template>
		<template #cell(exams_count)="row">
			<p class="p-2">
				<span v-if="row.item.response">
					<b>{{row.item.response.correct_points}} / {{row.item.response.max_points}}</b>
					{{ pointsPercentage(row.item.response.correct_points, row.item.response.max_point) }}
				</span>
				<span v-else-if="Array.isArray(row.item.responses) && row.item.responses.length">
					<b>{{row.item.responses[0].correct_points}} / {{row.item.responses[0].max_points}}</b>
					{{ pointsPercentage(row.item.responses[0].correct_points, row.item.responses[0].max_points) }}
				</span>
				<span v-else-if="row.item.task_responses && row.item.task_responses.length > 0">
					<b>{{ row.item.task_responses[0].correct_points}} / {{row.item.task_responses[0].max_points}}</b>
					{{ pointsPercentage(row.item.task_responses[0].correct_points, row.item.task_responses[0].max_points) }}
				</span>
				<span v-else :class="'red-group'">
					Nevypracované
				</span>
			</p>
		</template>
		<template #cell(submitted_at)="row">
			<span v-if="row.item.task_responses.length > 0">
				<span class="mr-1">{{ formatDate(row.item.task_responses[0].created_at) }}</span>
				<span class="ml-1">{{ formatTime(row.item.task_responses[0].created_at) }}</span>
			</span>
		</template>
		<template #cell(watch_time)> <!-- ="row"> -->
			<span class="mr-1">0:00</span>/
			<span class="ml-1">{{ task.course.video_duration }}</span>
		</template>
		<template v-for="slot in task.quiz_questions.length" v-slot:[toQuestionCellName(slot)]="data">
			<span v-b-tooltip.left.hover.viewport :title="data.value.response" :key="slot">
				<span v-if="data.value == ''">-</span>
				<svg-checkmark-green v-else-if="data.value.points == 1" />
				<svg-cross-red v-else />
				<svg-info-icon v-if="data.value" class="info-icon"/>
			</span>
		</template>
	</b-table>
</template>
<script>
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
import sortBy from 'lodash/sortBy'
import deburr from 'lodash/deburr'

export default {
	components: {
		// 'svg-cross-icon': () => import('@/plugins/appzmudri/_theme/icon/cross.svg?inline'),
		//'svg-bullet-icon': () => import('@/plugins/appzmudri/_theme/icon/bullet.svg?inline')
		'svg-info-icon': () => import('@/plugins/appzmudri/_theme/icon/info-icon.svg?inline'),
		'svg-checkmark-green': () => import('@/plugins/appzmudri/_theme/icon/checkmark-green.svg?inline'),
		'svg-cross-red': () => import('@/plugins/appzmudri/_theme/icon/cross-red.svg?inline')
	},

	props: {
		task: {
			required: true,
			type: Object
		},
		tableFields: {
			required: true,
			type: Array
		},
		tableHeader: {
			required: false,
			type: String,
			default: ''
		}
	},

	data() {
		return {
			tableItems: [],
		}
	},

	mounted() {
		this._fillTable()
	},

	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user.profile.type == 'teacher'
		}
	},

	methods: {
		toQuestionCellName (slot) {
			return `cell(question${slot})`
		},
		pointsPercentage(correct_points, max_points) {
			return ` (${Math.round(correct_points / max_points * 100)}%)`
		},
		formatDate(t) {
			return moment(t).utc().tz('Europe/Bratislava').format('DD.MM.YYYY')
		},
		formatTime(t) {
			return moment(t).utc().tz('Europe/Bratislava').format('HH:mm')
		},
		_fillTable(){
			this.tableItems = {...this.task.members_responses}
			Object.values(this.tableItems).forEach(entry => {
				if(!entry.task_responses.length) return
				const performance = entry.task_responses[0].quiz_performance // BE always sends array with 1 item instead of object
				performance.forEach((answer, i) => {
					entry['question'+(i+1)] = answer
				})
			})
			this.tableItems = sortBy(this.tableItems, e => deburr(e.user.surname))
		}
	}
}
</script>

<style lang="scss" scoped>
	svg, span {
		cursor: pointer;
	}
	.info-icon {
		margin: 0 0 5px 5px;
	}

	::v-deep table {
		overflow-y: hidden;
		overflow-x: hidden;
		thead {
			tr {
				border-bottom: 1px solid #CADEEA;
				th {
					text-align: center;
					padding: 10px 0;
					span {
						color: #86B2CD;
						font-weight: lighter;
						font-family: Poppins;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 21px;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 0;
					text-align: center;
					align-items: center;
					color: #2B2E4A;
					vertical-align: middle;

					p {
						margin-bottom: 0;
					}
					@media screen and (max-width: 600px) {
						font-size: 10px;

						p {
							font-size: 10px;
						}
					}

					button {
						color: #86B2CD !important;
						border-color: #86B2CD !important;
						font-size: small;
						font-weight: bold;
						min-width: 136px;
						min-height: 39px;
						padding: 0;

						&:hover {
							background-color: #FB2564;
							color: #FFFFFF !important;
							border-color: #FB2564 !important;
						}
					}
					a {
						text-decoration: none;
						color: #2B2E4A;
						font-weight: bold;
					}

				}
			}
		}
	}



	::v-deep .white {
		p,b {
			color: white;
		}
	}

	::v-deep .float-left {
		text-align: left;
		&:hover {
			color: #2B2E4A !important;
		}
	}

	::v-deep .align-start {
		text-align: start;
	}

	::v-deep .low-opacity {
		opacity: 50%;
	}

	::v-deep .align-end {
		text-align: end;
	}
	::v-deep .column-wider {
		width: 100px;
		@media screen and (min-width: 600px) {
			width: 300px;
		}
	}
</style>